body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* colors */
/* Color naming should increase from 1. (1 as main )*/
:root {
  --font-color-1: #212121;
  --bg-color-1: #ffffff;
  --link-color-1: #03a9f4;
  --green: #32a852;
  --blue: #387bff;
  --red: #df4747;
  --grey: #5f5f5f;
  --border-color-1: #c7c7c7c4;
}

.btns {
  width: auto;
  height: 35px;
  border: none;
  background-color: #3f51b5;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  min-width: 125px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

}

.btns:hover {
  background-color: transparent;
  border: 1px solid #3f51b5;
  color: #3f51b5;
}


.btns:disabled,
.btns[disabled]:hover {
  opacity: 0.5;
  background-color: #036ddf;
  color: white;
  cursor: auto;
}

.btns-outlined {
  background-color: transparent;
  border: 1px solid #036ddf;
  color: #036ddf;
}

.btns-green {
  background-color: var(--green);

}

.btns-red {
  background-color: var(--red);

}

.btns-red:hover {
  opacity: 1;
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.btns-green:hover {
  opacity: 1;
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green);
}

.btns-trans {
  background-color: transparent;
  color: var(--font-color-1);
  margin: 0;
  opacity: 0.8;
  box-shadow: none;
  height: auto;
}