.table-wrapper {
    margin: 0 auto;
    position: relative;
    overflow-x: auto;
    padding: 30px;
}

table.table.table-bordered {
    background-color: white;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    overflow: hidden;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.1071em;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
    -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.4);
}

table tr td input {
    width: 59px;
    text-align: center;
}

table tr:nth-child(even) {
    background-color: #f8f6ff;
}

th.all-month {
    width: 75px;
}

tr.all-total-row {
    background-color: #393939 !important;
    color: white;
    font-weight: 600;
}

tbody tr td {
    vertical-align: middle;
}

table.table.table-bordered thead tr th {
    background-color: #036ddf;
    color: white;
    padding: 16px;
    border-color: #036ddf;
    vertical-align: middle;
}

.allocation-commentBox {
    margin: 0 auto;
}

.allocation-commentBox .form-label {
    float: left;
}

.btn-wrapper {
    text-align: right;
}

.allocation-welcomeMsg {
    padding: 0 30px;
    text-align: left;

}

.allocation-welcomeMsg p {
    margin-bottom: 0px;

}

.bs-example {
    width: 500px;
    float: right;
    position: fixed;
    top: 30%;
    left: 30%;
}

.header-logoText {
    font-size: 24px;
    line-height: 36px;
    height: 46px;
    margin-bottom: 0px;
}

table.table.table-bordered.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}