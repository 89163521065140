.buddy-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 20px;
}

.buddy-container h3 {
    text-align: center;
}

.buddy-toolkit-link {
    color: blue !important;
    text-decoration: underline !important;
}