/* .App {
  text-align: center;
} */

a {
  text-decoration: none !important;
  color: inherit !important;
}

body {
  font-family: "Lato", sans-serif !important;
  background-color: #f5f5f5 !important;
}

.app {
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-link {
  text-decoration: none;
}

/* Styling for the tableau dashboard */
iframe {
  margin: auto;
  min-height: 1050px;
  display: flex;
  flex-direction: row;
}