/* .szh-menu__item {
    padding: 1.2rem 1.5rem !important;
} */


.szh-menu__item a {
    text-decoration: none !important;
    color: black;
}

.react-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Base styling for the navbar itself */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding: .6rem 1rem;
    background-color: #f1f1f1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .28);
    margin-bottom: 50px;
}

/* Logo section */
.navbar .logo {
    flex-grow: 1;
}

.logo-image {
    height: 60px;
}

/* Navigation menu */
.navbar .menu {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.navbar .menu span {
    text-decoration: none;
    color: rgb(56, 56, 56);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: block;
    cursor: pointer;
    font-size: 18px;
}

.navbar .menu span:hover {
    background-color: rgba(51, 51, 51, 0.1);
}

/* User icon section */
.navbar .user {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    color: #686868;
}

.user-icon {
    height: 30px;
}