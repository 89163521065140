.card {
  border-radius: 10px !important;
  cursor: pointer;
  background: #fff;
}

.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-text {
  font-size: 1.9rem;
}

.container-fluid {
  padding-top: 6rem;
}

.card-img-top {
  transform: scale(1);
  background-image: initial;
  padding-top: 10px;
  width: 14rem;
  height: 14rem;
}

/* Make image hoover  */
/* .card-img-top:hover {
  transform: scale(1.2);
} */

.overflow {
  overflow: hidden;
}

h4 {
  font-size: 1.8rem;
  font-weight: normal;
}